$.fn.lib_reCapcha = function (attr) {
    let elm = $(this);
    if(elm.length) {
        $.importScript(cdnjs.recaptcha.replace("{apikey}",attr), function(){
            grecaptcha.enterprise.ready(() => {
                grecaptcha.enterprise.execute(attr, { action: 'form' }).then(token => {
                    elm.find(`[name="gtoken"]`).val(token);
                })
            })
        })
    }
};
